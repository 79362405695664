import { useEffect } from "react";
import "./_cardRelatorio.scss";

function CardRelatorio({
    percent,
    estagio,
    description,
    descriptionStage,
    score,
    total_score,
    sector,
    percent_attendance,
    banchmark_percent_attendance,
    banchmark,
}) {
    const cyrcleStorage = JSON.parse(localStorage.getItem("@cycleChosed"));

    useEffect(() => {
        let progresFilBg = document.querySelector(".scale-progress__fill-bg");
        progresFilBg.style.width = `${percent}%`;
    }, [percent]);

    return (
        <div className="card-relatorio">
            <h4>Resultado Geral</h4>
            <div className="card-relatorio__box-percentual">
                <div className="scale-progress" cor="red">
                    <div className="scale-progress__fill-bg"></div>
                    <div className="description-stages">
                        <p>Iniciante</p>
                        <p>Intermediário</p>
                        <p>Avançado</p>
                        <p>Referência</p>
                    </div>
                </div>
                <span className="relatorio__company-result-percent">
                    {percent}%
                </span>

                <div className="relatorio__box-result">
                    <p>
                        Estágio:{" "}
                        <span className="style-uppercase">{estagio}</span>
                    </p>
                    <p>{descriptionStage}</p>
                </div>
            </div>

            <p className="pontuacao_empresa">
                <span>Pontuação da empresa:</span> {score} pontos
            </p>

            <p
                className="card-relatorio__description"
                dangerouslySetInnerHTML={{ __html: description }}
            ></p>


            {cyrcleStorage != 3 &&
                <>
                    <p className="title-resultado-geral-setor">
                        Resultado Geral do Setor
                    </p>
                    <p className="pontuacao_empresa">
                        <span>Resultado do setor:</span> {sector} pontos
                        (mediana)
                    </p>
                    <p className="pontuacao_empresa">
                        <span>Percentual de atendimento:</span>{" "}
                        {percent_attendance}%
                    </p>

                    <br />
                    <p className="title-resultado-geral-setor">
                        Resultado Benchmark
                    </p>
                    <p className="pontuacao_empresa">
                        <span>Resultado benchmark:</span> {banchmark} pontos
                        (média)
                    </p>
                    <p className="pontuacao_empresa">
                        <span>Percentual de atendimento:</span>{" "}
                        {banchmark_percent_attendance}%
                    </p>

                    <br />
                    <p className="pontuacao_total">
                        <span>Nota1:</span> Pontuação máxima = {total_score}{" "}
                        pontos
                    </p>
                    <p className="pontuacao_total">
                        <span>Nota2:</span> O resultado benchmark corresponde a
                        25% das maiores notas inseridas no sistema.
                    </p>
                </>
            }
        </div>
    );
}

export default CardRelatorio;

